import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import Footer from '../components/Footer';
import PortFolio from '../assets/images/port_folio.png';
import JobTitles from '../assets/images/job_titles.png';
import VerticalNavbar from '../components/Navbar'; // Import the new component

const New = () => {
  const navigate = useNavigate();

  const handleWorkButtonClick = () => {
    navigate('/work');
  };

  return (
    <div className="home">
      <VerticalNavbar /> {/* Add the vertical navbar */}
      <section id="section1" className="section1">
        <img src={PortFolio} className="image" alt="PortFolio" />
        <img src={JobTitles} className="image" alt="JobTitles" />
      </section>
      <section id="section2" className="section2">
        <h1>About me</h1>
        <p>
          Hello, I am a software developer based in Stockholm. I have a
          background in design and a growing expertise in coding. I thrive at
          the intersection of creativity and technology. As a designer I
          understand the importance of blending form with function to create
          compelling digital experiences. On the programming side, I'm
          continuously honing my skills and seeking out new challenges to push
          myself further.
        </p>
        <div className="home-row">
          <div>
            <h3>I live in</h3>
            <p>Stockholm, Sweden</p>
          </div>
          <div>
            <h3>I am</h3>
            <p>18 years old</p>
          </div>
        </div>
      </section>
      <section id="section3" className="section3">
        <div>
          <h2>My work</h2>
          <button
            className="custom-button black"
            onClick={handleWorkButtonClick}
          >
            See my work
          </button>
        </div>
        <div>
          <h3>Software Development</h3>
          <p>
            My primary expertise lies in Flutter development, where I deliver
            functional and aesthetically pleasing mobile applications. In
            addition to Flutter, I am well-versed in Node.js, which lets me
            handle backend development efficiently. I have experience in
            creating RESTful APIs, managing server-side logic, and integrating
            various services. Moreover, my experience with SQL databases adds
            another dimension to my development capabilities. I have built and
            managed databases using SQL, ensuring data integrity, optimizing
            queries for performance, and designing database schemas that support
            the application's requirements. This expertise enables me to handle
            data storage and retrieval efficiently, contributing to the overall
            reliability and performance of the applications I develop. My
            passion for continuous learning and problem-solving drives me to
            stay updated with the latest industry trends and best practices.
          </p>
          <h3>Design</h3>
          <p>
            As a designer, I bring creativity, precision, and a keen eye for
            detail to every project I undertake. With expertise in Figma, I
            specialize in crafting intuitive and visually captivating user
            interfaces for both mobile apps and websites. Additionally, my
            proficiency extends to graphic design, where I leverage Adobe
            Illustrator to create compelling logos and icons that encapsulate
            brand identity and convey messaging effectively.
          </p>
          <h3>Art</h3>
          <p>
            Art has always been an integral part of my creative journey, serving
            as both a passion and a means of expression. With a natural knack
            for drawing, I have honed my skills over the years, transitioning
            primarily to digital mediums to explore new horizons in creativity
            and innovation. My artistry shines through in my digital
            illustrations, which have become an essential component of my design
            and app projects. While my illustrations serve a practical purpose
            in my professional endeavors, I also draw purely for the joy of
            creation. Whether it's experimenting with new techniques, exploring
            different styles, or simply sketching for relaxation, art remains a
            constant source of inspiration and fulfillment in my life.
          </p>
        </div>
      </section>
      <section id="section4" className="section4">
        <h2>Contact</h2>
        <p>Got a question or a proposal? Feel free to send me a message</p>
        {/* <div className="home-row">
          <div className="form-group">
            <input type="text" id="name" />
            <label htmlFor="name">Name</label>
          </div>
          <div className="form-group">
            <input type="text" id="email" />
            <label htmlFor="email">Email address</label>
          </div>
        </div>
        <div className="form-group" style={{ marginTop: '50px' }}>
          <textarea id="message" rows="4"></textarea>
          <label htmlFor="message">Message</label>
        </div>
        Use mailto protocol for sending email */}
        <a href="mailto:adora@lovbrand.com" className="custom-button black">
          Send message
        </a>
      </section>
      <Footer />
    </div>
  );
};

export default New;
