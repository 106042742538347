import React from 'react';
import './Welibri.css';
import WelibriScreens from '../assets/images/welibriscreens.png';
import Footer from '../components/Footer';

const Welibri = () => {
  const handleButtonClick = () => {
    window.open('https://welibri.com/', '_blank');
  };

  return (
    <div className="welibri">
      <img src={WelibriScreens} className="preview" alt="WelibriScreens" />
      <h1>Welibri</h1>
      <div className="box">
        <p>
          I am very excited to share that I've been working on a mobile app,
          Welibri, which is now in its final stages of development. Welibri is a
          social media platform designed specifically for book lovers. As an
          avid reader myself, I have implemented features that I felt were
          missing in other book-related apps.
        </p>
        <button className="custom-button black" onClick={handleButtonClick}>
          Check out our website
        </button>
        <h3>Design and Branding</h3>
        <p>
          This project has taught me a lot and pushed my limits beyond
          programming and app design. It has familiarized me with other crucial
          aspects of building an app, such as logo and icon design, typography,
          and brand definition. The design process for Welibri has been both
          challenging and rewarding. From selecting the right color schemes to
          designing intuitive navigation, every element has been carefully
          crafted to enhance the user experience.
        </p>
        <h3>Frontend & Backend Development</h3>
        <p>
          Developing Welibri has significantly enhanced my knowledge and skills
          in both frontend and backend development. On the frontend, I delved
          deep into creating a user-friendly interface that is not only visually
          appealing but also functional. On the backend, I gained a
          comprehensive understanding of server-side technologies, database
          management, and API integration. This involved optimizing performance,
          ensuring data security, and implementing efficient data retrieval and
          storage mechanisms.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Welibri;
