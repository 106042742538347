import React from 'react';
import './Gallery.css';
import EventApp from '../assets/gallery/eventapp.png';
import AbstractFurniture from '../assets/gallery/abstractfurniture.png';
import ArtApp from '../assets/gallery/artapp.png';
import FikaHaven from '../assets/gallery/fikahaven.png';
import Footer from '../components/Footer';

const Gallery = () => {
  return (
    <div className="gallery">
      <h1>Gallery</h1>
      <div className="image-gallery">
        <img src={EventApp} className="image" alt="PortFolio" />
        <img src={AbstractFurniture} className="image" alt="PortFolio" />
        <img src={ArtApp} className="image" alt="PortFolio" />
        <img src={FikaHaven} className="image" alt="PortFolio" />
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
