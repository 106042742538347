import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Work.css';
import Welibri from '../assets/images/Welibri.png';
import Footer from '../components/Footer';
import Figma from '../assets/icons/figma.png';

const Work = () => {
  const navigate = useNavigate();

  const handleWorkButtonClick = () => {
    navigate('/welibri');
  };

  return (
    <div className="work">
      <h2>Design</h2>
      <div className="box">
        {' '}
        <img
          style={{ height: '40px', width: '40px' }}
          src={Figma}
          className="image"
          alt="PortFolio"
        />
        <h3>UI kits</h3>
        <p>
          In my spare time, when I feel inspired I like to create designs and
          sometimes I share them as templates on my Figma Community page. I
          invite you to view them and I hope you enjoy them
        </p>
        <a
          href="https://www.figma.com/@adoralovbrand"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="custom-button black">Go to my Figma page</button>
        </a>
        <h3>Mobile app & web design</h3>
        <p>
          On my Behance, I share projects that I'm particularly proud of. Feel
          free to take a look—they range from mobile app designs to website
          designs
        </p>
        <a
          href="https://www.behance.net/adoralvbrand"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="custom-button black">
            View more designs on my Behance
          </button>
        </a>
      </div>
      <h2>Software Development</h2>
      <div className="box">
        <img src={Welibri} className="app-icon" alt="Welibri" />
        <h3>Welibri</h3>
        <button className="custom-button black" onClick={handleWorkButtonClick}>
          Read more
        </button>
        <p>
          I am very excited to share that I've been working on a mobile app,
          Welibri, which is now in its final stages of development. Welibri is a
          social media platform designed specifically for book lovers. As an
          avid reader myself, I have implemented features that I felt were
          missing in other book-related apps
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Work;
