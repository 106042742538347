import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import Button from './Button';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <h3>You've reached the end of the page. Check out my work below</h3>
      </div>
      <div className="footer-middle">
        <div className="footer-column">
          <Link to="/">
            <Button text="Home" />
          </Link>
          <Link to="/work">
            <Button text="Work" />
          </Link>
          <Link to="/gallery">
            <Button text="Gallery" />
          </Link>
        </div>
        <div className="footer-column">
          <a
            href="https://www.figma.com/@adoralovbrand"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button text="Figma" />
          </a>
          <a
            href="https://www.behance.net/adoralvbrand"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button text="Behance" />
          </a>
          <a
            href="https://dribbble.com/Adolov/shots"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button text="Dribbble" />
          </a>
        </div>
        <div className="footer-text">
          <p>
            Welcome to my portfolio, I am a software developer based in
            Stockholm. I have a background in design and a growing expertise in
            coding. I thrive at the intersection of creativity and technology.
            As a designer I understand the importance of blending form with
            function to create compelling digital experiences. On the
            programming side, I'm continuously honing my skills and seeking out
            new challenges to push myself further.
          </p>
        </div>
      </div>
      <p>&copy; 2024 Adora Portfolio. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
