// VerticalNavbar.js
import React, { useState, useEffect } from 'react';
import './Navbar.css';

const VerticalNavbar = () => {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const sections = document.querySelectorAll('section');
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.6, // Adjust the threshold as needed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  const getLinkClassName = (sectionId) => {
    if (activeSection === sectionId) {
      return sectionId === 'section3' ? 'section3-active' : 'active';
    }
    return '';
  };

  return (
    <nav className="navbar">
      <a href="#section1" className={getLinkClassName('section1')}>
        1
      </a>
      <a href="#section2" className={getLinkClassName('section2')}>
        2
      </a>
      <a href="#section3" className={getLinkClassName('section3')}>
        3
      </a>
      <a href="#section4" className={getLinkClassName('section4')}>
        4
      </a>
    </nav>
  );
};

export default VerticalNavbar;
